<template>
  <div class="detail">
    <van-nav-bar
        title="Order Status"
        fixed
        placeholder
        left-arrow
        :border="false"
        @click-left="router.go(-1)"
    />
    <div class="top">
      <div class="title">Product Information</div>
      <div class="name">
        <img src="@/assets/imgs/icon_order.png" alt="">
        <div class="text">{{ orderInfo.name }}</div>
      </div>
      <div class="time">Order Date: {{ orderInfo.add_time }}</div>
    </div>
    <div class="main">
      <div class="title">Receiver Information</div>
      <div class="list">
        <div class="item">
          <div class="label">Receiver</div>
          <div class="value">{{ orderInfo.u_name }}</div>
        </div>
        <div class="item">
          <div class="label">International Mobile Number</div>
          <div class="value">{{ orderInfo.mobile }}</div>
        </div>
        <div class="item">
          <div class="label">Login E-mail</div>
          <div class="value">{{ orderInfo.login_email }}</div>
        </div>
        <div class="item">
          <div class="label">Contact E-mail</div>
          <div class="value">{{ orderInfo.email }}</div>
        </div>
        <div class="item">
          <div class="label">Wallet Address</div>
          <div class="value">{{ orderInfo.receiving_address }}</div>
        </div>
        <div class="item">
          <div class="label">Address</div>
          <div v-if="orderInfo.country_name" class="value">
            {{ orderInfo.country_name + ' ' + orderInfo.area_line_1 + ' ' + orderInfo.area_line_2 + ' ' + orderInfo.city + ' ' + orderInfo.province }}
          </div>
        </div>
      </div>
    </div>
    <div class="order">
      <div class="title">
        <div class="text">Order Status</div>
        <img src="@/assets/imgs/icon_why.png" @click="openZendesk">
        <!--<van-popover v-model:show="show1" placement="left" theme="dark">
          <div class="tips">Contact Customer Service.</div>
          <template #reference>
            <img src="@/assets/imgs/icon_why.png" @click="openZendesk">
          </template>
        </van-popover>-->
      </div>
      <div class="step">
        <div class="item">
          <div class="text">
            <div class="circle"></div>
            <div>Product Redeemed</div>
          </div>
          <div class="time">{{ orderInfo.add_time }}</div>
          <div class="line"></div>
        </div>
        <div class="item">
          <van-popover v-model:show="show" placement="right" theme="dark">
            <div class="tips">Please contact our customer<br/>services for more information.</div>
            <template #reference>
              <div class="text">
                <div class="circle"></div>
                <div>Status</div>
                <img src="@/assets/imgs/icon_info.png" alt="">
              </div>
            </template>
          </van-popover>
          <div v-if="orderInfo.order_status == 1" class="time">Shipping</div>
          <div v-if="orderInfo.order_status == 2" class="time">Successfully Redeemed</div>
          <div v-if="orderInfo.order_status == 3" class="time">Failed</div>
          <div class="line second"></div>
        </div>
        <div class="item">
          <div class="text">
            <div class="circle"></div>
            <div>Tracking Number</div>
          </div>
          <div class="time">{{ orderInfo.shipping_code != 0 ? orderInfo.shipping_code : 'Data Not Found'}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { orderDetail } from '@/request/api'

  const router = useRouter()
  const route = useRoute()
  const order_id = route.query.id
  const orderInfo = ref({})
  orderDetail({
    address: sessionStorage.getItem('address'),
    // address: '0x0000000000000000000000000000000000000000',
    order_id
  }).then(res => {
    if(res.success) {
      orderInfo.value = res.data
    }
  })

  function openZendesk() {
    zE('webWidget', 'show');
    zE('webWidget', 'open');
  }

  const show = ref(false)
  const show1 = ref(false)
</script>

<style lang="less" scoped>
.detail{
  color: #F8F8F8;

  .top{
    background-color: #101010;
    padding: 10px 24px;
    margin: 20px 0 12px 0;

    .name{
      display: flex;
      align-items: center;

      img{
        width: 30px;
      }
      .text{
        font-size: 16px;
        margin-left: 13px;
      }
    }
    .time{
      color: #697377;
      font-size: 16px;
      line-height: 24px;
      padding-left: 43px;
    }
  }
  .title{
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 12px;
  }
  .main{
    background-color: #101010;
    padding: 10px 24px 24px 24px;
    margin-bottom: 12px;

    .list{
      .item{
        margin-bottom: 12px;

        .label{
          font-size: 14px;
          line-height: 20px;
          color: #697377;
          margin-bottom: 8px;
        }
        .value{
          font-size: 16px;
          line-height: 24px;
          color: #F8F8F8;
          border-bottom: 1px solid #06C8A1;
          word-break: break-all;
        }
      }
      .item:last-child{
        margin-bottom: 0;
      }
    }
  }
  .order{
    padding: 10px 24px;
    background-color: #101010;

    .title{
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text{
        color: #F8F8F8;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
      }
      img{
        width: 24px;
      }
    }
    .step{
      .item{
        position: relative;
        padding-left: 12px;
        margin-bottom: 14px;

        .text{
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;

          .circle{
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #06C8A1;
            margin-right: 16px;
          }
          img{
            width: 12px;
            margin-left: 10px;
          }
        }
        .time{
          color: #697377;
          font-size: 14px;
          line-height: 20px;
          padding-left: 24px;
        }
        .line{
          width: 1px;
          height: 45px;
          background-color: #06C8A1;
          position: absolute;
          top: 12px;
          left: 15.5px;
        }
        .second{
          top: 25px;
        }
      }
      .item:first-child{
        margin-bottom: 0;
      }
    }
  }
}
</style>
